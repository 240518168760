import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import "./index.scss"

import Button from "react-bootstrap/Button"
import { Container, Title, Text, Form } from "components/Form"
import { ConfirmDialog, Popup } from "components"
import { auth, loading, message, teamStatus } from "state_management"

export default function LoginScreen() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Login form fields
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [token, setToken] = useState("")
  // Info message visibility and control
  const [showInfoMessage, setShowInfoMessage] = useState(false)
  const [position, setPosition] = useState(null)
  // Token visibility and control
  const [showTokenField, setShowTokenField] = useState(!!parseInt(localStorage.getItem("tokenExpireTime"), 10))
  const [showPopup, setShowPopup] = useState(false)

  const handleLogin = () => {
    dispatch(auth.login(username, password))
      .then((res) => {
        handleSuccesfulLogin(res)
      })
      .catch((e) => {
        // If a the user is logged in elsewhere
        if (e.message === "You are logged in somewhere else") {
          setShowPopup(true)
        }
        // If the user needs to verify their device with MFA
        if (e.message === "You need to verify your device with Multi-Factor Authentication") {
          handleGenerateToken()
        }
      })
  }

  const handleSuccesfulLogin = (res) => {
    if (res.role !== "admin" && res.role !== "customer_admin") {
      const isOnlyLibrarian = res.isOnlyLibrarian
      const isMember = res.role === "member"
      const memberId = res.id
      dispatch(teamStatus.fetchCurrent()).then((res) => {
        const onlyLibrarian = !res.status.showAnalyses && !res.status.showProjects && res.status.showLibrarian
        // This checks if FE in browser is behind FE on server and reloads
        // Page if it is. Otherwise we fetch all as usual
        const oldVersion = JSON.parse(window.localStorage.getItem("plannertechVersion"))
        if (oldVersion !== res.status.plannertechVersion) {
          var localStorageEnabled = true
          try {
            window.localStorage.setItem("plannertechVersion", JSON.stringify(res.status.plannertechVersion))
          } catch (e) {
            localStorageEnabled = false
          }
          if (localStorageEnabled) {
            document.location.reload()
          } else {
            dispatch(loading.fetchAll())
            dispatch(message.success("Welcome back!"))
            if (isOnlyLibrarian || onlyLibrarian) {
              navigate("/librarian")
            } else if (isMember && res.options.directToMemberPlan) {
              navigate("/member/" + memberId)
            } else {
              navigate("/")
            }
          }
        } else {
          dispatch(loading.fetchAll())
          dispatch(message.success("Welcome back!"))
          if (isOnlyLibrarian || onlyLibrarian) {
            navigate("/librarian");
          } else if (isMember && res.options.directToMemberPlan) {
            navigate("/member/" + memberId)
          } else {
            navigate("/")
          }
        }
      })
    } else {
      dispatch(message.success("Welcome back!"))
      navigate("/locations")
    }
  }

  const handleGenerateToken = () => {
    dispatch(auth.getMfaToken(username, password))
      .then(() => {
        setShowTokenField(true)
        setShowInfoMessage(true)
      })
      .catch((e) => {
        if ( e.message === "Too many Authentication code requests today. Your account have been blocked until tomorrow" ) {
          resetView()
        }
      })
  }

  const handleTokenVerification = () => {
    // Verify the token logic
    dispatch(auth.verifyMfaToken(username, password, token))
      .then((res) => {
        setShowTokenField(false)
        handleSuccesfulLogin(res)
      })
      .catch((e) => {
        // If a the user is logged in elsewhere
        if (e.message === "You are logged in somewhere else") {
          setShowPopup(true)
        } else if (
          e.message === "Too many wrong attempts" ||
          e.message === "Authentication code has expired" ||
          e.message === "Too many Authentication code requests today. Your account have been blocked until tomorrow"
        ) {
          resetView()
        }
      })
  }

  function handleSubmit() {
    console.log("logging in...")
    if (showTokenField) {
      handleTokenVerification()
    } else {
      handleLogin()
    }
  }

  const resetView = () => {
    // reset states
    setShowTokenField(false)
    setShowPopup(false)
    setToken("")
  }

  return (
    <div className="LoginScreen-main-container">
      <Form className="LoginScreen-content" onSubmit={handleSubmit}>
        {showPopup && (
          <ConfirmDialog
            onCancel={() => setShowPopup(false)}
            onConfirm={() => {
              setShowPopup(false)
              console.log("logging in...")
              dispatch(auth.logoutOtherDevice(username, password))
                .then((res) => {
                  handleSuccesfulLogin(res)
                })
                .catch((e) => {
                  if (e.message === "You need to verify your device with Multi-Factor Authentication") {
                    setShowTokenField(true)
                  }
                })
            }}
          >
            <h4>User already logged in. To take over session press confirm</h4>
          </ConfirmDialog>
        )}
        <h1>Plannertech</h1>
        <Container>
          <Title>Username</Title>
          <Text
            required
            type="text"
            value={username}
            onChange={(str) => {
              setUsername(str)
              if (showTokenField) {
                resetView()
              }
            }}
          />
        </Container>
        <Container>
          <Title>Password</Title>
          <Text
            required
            type="password"
            value={password}
            onChange={(str) => {
              setPassword(str)
              if (showTokenField) {
                resetView()
              }
            }}
          />
        </Container>
        {showTokenField && (
          <>
            <Container>
              <Text type="text" required placeholder="Authentication Code" value={token} onChange={setToken} />
            </Container>
            {showInfoMessage && (
              <Popup
                onCancel={() => setShowInfoMessage(false)}
                singleMessage={
                  <p>We have sent an authentication code to your email. Please enter it above before login</p>
                }
                position={position}
              />
            )}
          </>
        )}
        <Button
          className="login-button"
          type="submit"
          onClick={(event) => {
            if (!showTokenField) {
              const buttonRect = event.target.getBoundingClientRect()
              setPosition({ top: buttonRect.top })
            }
          }}
        >
          Login
        </Button>
      </Form>
    </div>
  )
}
