import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// import "./index.scss"

import { ConfigContainer } from "components"
import { Title, Container, Text } from "components/Form"
import { message, repositories, librarian } from "state_management"
import { AiOutlineExclamation } from "react-icons/ai"

// Location config used by admins.
export default function RepositoryConfig({ customerAdminView = false, repositoryData, onClose }) {
  const dispatch = useDispatch()
  const [name, setName] = useState(repositoryData ? repositoryData.name : "")
  const role = useSelector((state) => state.auth.role)
  const [prompt, setPrompt] = useState(repositoryData ? repositoryData.promptPrefix : "")

  return (
    <ConfigContainer
      title={repositoryData ? "Edit repository" : "Add repository"}
      keyWordForAllowedDeletion="DELETE"
      deleteHandler={repositoryData ? (allowDeletion) => {
        if (allowDeletion === "DELETE") {
          if (repositoryData) {
            if (customerAdminView) {
              dispatch(repositories.remove(repositoryData.id, customerAdminView)).then(onClose)
            } else {
              dispatch(repositories.remove(repositoryData.id)).then(onClose)
            }
          }
        } else {
          dispatch(message.info("You did not spell DELETE properly"))
        }
      } : undefined}
      submitHandler={() => {
        const res = role === "customer_admin" ? { name, prefix: prompt } : { name }
        if (prompt.length > 1200) {
          dispatch(message.error("Prompt too long (max is 1200 characters)"))
          return
        }
        if (repositoryData) {
          if (role === "customer_admin") {
            dispatch(repositories.editAdmin(repositoryData.id, res, customerAdminView)).then(onClose)
          } else if (role !== "planner") {
            dispatch(repositories.edit(repositoryData.id, res, customerAdminView)).then(onClose)
          } else {
            dispatch(librarian.editRepository(repositoryData.id, res)).then(onClose)
          }
        } else {
          if (customerAdminView) {
            dispatch(repositories.createGlobal(res)).then(onClose)
          } else {
            if (role !== "planner") {
              dispatch(repositories.create(res)).then(onClose)
            } else {
              dispatch(librarian.createTeamRepository(res)).then(onClose)
            }
          }
        }
      }}
      cancelHandler={onClose}
    >
      <Container>
        <Title>Repository Name</Title>
        <Text value={name} required onChange={setName} />
      </Container>
      {role === "customer_admin" && (
        <Container>
          <Title>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <span>Prompt Prefix</span>
              {prompt.length > 1200 && (
                <AiOutlineExclamation
                  style={{ color: "red", marginTop: "4px" }}
                  size={40}
                  title="Prompt too long (max is 1200 characters)"
                />
              )}
            </div>
          </Title>
          <Text large={true} value={prompt} onChange={setPrompt}/>
        </Container>
      )}
    </ConfigContainer>
  )
}
