import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import Button from "react-bootstrap/Button"
import InputGroup from "react-bootstrap/InputGroup"
import { Title, DayPicker, DurationInput } from "components/Form"
import { reports, message } from "state_management"
import * as XLSX from "xlsx"

import "./index.scss"
import { DateTime } from "luxon"

// Makes the client download an excel file with data
export default function ReportConfig({ onClose, id }) {
  const [analysisTaskCheck, setAnalysisTaskCheck] = useState(false)
  const [fromDate, setFromDate] = useState(null)
  const [fromTime, setFromTime] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [toTime, setToTime] = useState(null)
  const [isDaypickerOpen, setIsDaypickerOpen] = useState(false)

  const dispatch = useDispatch()


  useEffect(() => {
    // Callback function to execute when mutations are observed
    const observerCallback = (mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const dayPickerElements = document.getElementsByClassName("DayPicker-OverlayWrapper");
          if (dayPickerElements.length > 0 && !isDaypickerOpen) {
            setIsDaypickerOpen(true);
          } else if (dayPickerElements.length === 0 && isDaypickerOpen) {
            setIsDaypickerOpen(false);
          }
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(observerCallback);

    // Start observing the target node for configured mutations
    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup function to disconnect the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, [isDaypickerOpen]);


  function validateSubmit() {
    if (!fromDate || !fromTime || !toDate || !toTime) {
      return "Please fill out all period fields"
    }
    if (!analysisTaskCheck) {
      return "Please select at least one report type"
    }
    return false
  }

  function createExcelData(columns, rows, fileName) {
    let sortedColumns = columns.sort((a, b) => {
      return b.columnIndex > a.columnIndex ? -1 : b.columnIndex < a.columnIndex ? 1 : 0
    })
    let newRows = []
    rows.forEach((row) => {
      const newRow = {}
      if (row.finishTime) {
        newRow["finishTime"] = modifyDateStr(row.finishTime)
      }
      if (row.startTime) {
        newRow["startTime"] = modifyDateStr(row.startTime)
      }
      newRows.push({ ...row, ...newRow })
    })
    return { dataset: newRows, columns: sortedColumns, fileName: fileName }
  }

  function modifyDateStr(str) {
    let a = DateTime.fromISO(str)
    return (
      a.year.toString() +
      "-" +
      a.month.toString() +
      "-" +
      a.day.toString() +
      " " +
      a.hour.toString() +
      ":" +
      a.minute.toString()
    )
  }

  function handleExportExcel(report) {
    // Extract headers from columns
    const headers = report.columns.map((column) => column.name)
    // Extract data using keys from columns, ensuring order
    const data = report.dataset.map((row) => report.columns.map((column) => row[column.key]))
    // Create an array of arrays with headers and data
    const dataWithHeaders = [headers, ...data]
    // Create a new worksheet
    const ws = XLSX.utils.aoa_to_sheet(dataWithHeaders)

    // Create a new workbook
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, report.fileName)

    // Export the workbook
    XLSX.writeFile(wb, `${report.fileName}.xlsx`)
  }

  function submitFunction() {
    const msg = validateSubmit()
    if (msg) {
      dispatch(message.warning(msg))
      return
    }
    dispatch(reports.analysisTasks(fromDate.plus(fromTime), toDate.plus(toTime), id)).then((res) => {
      const propertyObj = createExcelData(res.columns, res.rows, res.filename)
      if (!propertyObj.columns.length) {
        dispatch(message.warning("The " + propertyObj.fileName + " is empty in the given interval"))
        return
      }
      // Directly handle Excel export
      handleExportExcel(propertyObj)
    })
  }

  return (
    <div className="ReportConfig-container">
      <h3>Report Generator</h3>
      <div className="ReportConfig-From">
        <InputGroup>
          <InputGroup.Text>From</InputGroup.Text>
          <DayPicker
            value={fromDate}
            onChange={(event) => {
              if (!event) {
                setFromDate(undefined)
                return
              }
              if (event.invalid) {
                return
              }
              setFromDate(event)
            }}
          ></DayPicker>
          <DurationInput value={fromTime} onChange={setFromTime} isTime />
        </InputGroup>
      </div>
      <div className="ReportConfig-To">
        <InputGroup.Text>To</InputGroup.Text>
        <DayPicker
          value={toDate}
          onChange={(event) => {
            if (!event) {
              setToDate(undefined)
              return
            }
            if (event.invalid) {
              return
            }
            setToDate(event)
          }}
        />
        <DurationInput value={toTime} onChange={setToTime} isTime />
      </div>
      <div className={`ReportConfig-CheckBox ${isDaypickerOpen ? "open" : ""}`}>
        <InputGroup>
          <Title>Analysis Task - Time, Performer, Number of Samples</Title>
          <input
            type="checkbox"
            defaultChecked={analysisTaskCheck}
            onClick={() => setAnalysisTaskCheck(!analysisTaskCheck)}
          />
        </InputGroup>

        {/* {showDownload &&
          excelProperties.map((obj) => (
            <div className="ReportConfig-DownloadBtns" key={obj.fileName}>
              <Button onClick={() => handleExportExcel(obj)}>Download {obj.fileName}</Button>
            </div>
          ))} */}
      </div>
      <div className="ReportConfig-buttons">
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={submitFunction}>
          Generate
        </Button>
      </div>
    </div>
  )
}
