import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import "./index.scss"

import { ConfigContainer, Popup } from "components"
import ToggleButton from "react-bootstrap/ToggleButton"
import Button from "react-bootstrap/Button"
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup"
import { Title, Container, Text, Select } from "components/Form"
import { FiCopy } from "react-icons/fi"

import { teamsOnLocation, token, message } from "state_management"

// Team config used by admins.
export default function TeamConfig({ locationId, teamId, onClose }) {
  const dispatch = useDispatch()
  const team = useSelector((state) => state.teamsOnLocation[locationId].teams.find((team) => team.id === teamId))
  const [name, setName] = useState(team ? team.name : "")
  const [showProjects, setShowProjects] = useState(team ? team.showProjects : true)
  const [showAnalyses, setShowAnalyses] = useState(team ? team.showAnalyses : true)
  const [showLibrarian, setShowLibrarian] = useState(team ? team.showLibrarian : true)
  const [showToken, setShowToken] = useState(false)
  const isCustomerAdmin = useSelector((state) => state.auth.role) === "customer_admin"

  const tokenString = useSelector((state) => state.token.token)

  const [repositoryIds, setRepositoryIds] = useState(team?.repositoryIds || [])
  const [chatbotIds, setChatbotIds] = useState(team?.chatbotIds || [])

  const showForecast = team ? team.showForecast : false

  const assignableRepositories = useSelector((state) => state.repositories.assignableRepositories)
  const chatbots = useSelector((state) => state.librarian.chatbots)

  const handleCopy = () => {
    navigator.clipboard
      .writeText(tokenString)
      .then(() => {
        dispatch(message.success("Copied to clipboard"))
      })
      .catch(() => {
        dispatch(message.error("Could not copy to clipboard"))
      })
  }

  return (
    <ConfigContainer
      title={team ? "Edit team" : "Add team"}
      deleteIsImportant
      deleteHandler={team ? () => dispatch(teamsOnLocation.remove(locationId, team.id)).then(onClose) : undefined}
      submitHandler={() => {
        const res = { name, showProjects, showAnalyses, showLibrarian, showForecast, repositoryIds, chatbotIds }
        if (team) {
          dispatch(teamsOnLocation.edit(locationId, team.id, res)).then(onClose)
        } else {
          dispatch(teamsOnLocation.create(locationId, res)).then(onClose)
        }
      }}
      cancelHandler={onClose}
    >
      {showToken && (
        <Popup
          nonScrollable
          className="tokenPopupContainer"
          onCancel={() => {
            dispatch(token.hideToken()) // Delete the token from redux
            setShowToken(false)
          }}
        >
          <div>
            <h4>Permanent API token</h4>
            <p>
              This is a permanent API token, which allows you to access your team via our API. Copy and store it in a
              save place.
            </p>
            <Container>
              <Title>API token</Title>
              <Title>
                {tokenString}{" "}
                <span title="copy to clipboard" className="teamconfig-copy-clipboard">
                  <FiCopy onClick={handleCopy} />
                </span>
              </Title>
            </Container>
          </div>
          <Button
            className="close-popup-button"
            variant="primary"
            onClick={() => {
              dispatch(token.hideToken()) // Delete the token from redux
              setShowToken(false)
            }}
          >
            Ok
          </Button>
        </Popup>
      )}
      <div className="container-compartment">
        <Container>
          <Title>Team Name</Title>
          <Text value={name} required onChange={setName} />
        </Container>
        <Container>
          <Title>Show Projects</Title>
          <ToggleButtonGroup
            type="radio"
            className="TeamConfig-toggleButtonGroup"
            name="showProjects"
            defaultValue={showProjects}
            onChange={setShowProjects}
          >
            <ToggleButton
              value={true}
              variant="light"
              id="teamconfig-showprojects_yes"
              className="TeamConfig-radioButton"
            >
              Yes
            </ToggleButton>
            <ToggleButton
              value={false}
              variant="light"
              id="teamconfig-showprojects_no"
              className="TeamConfig-radioButton"
            >
              No
            </ToggleButton>
          </ToggleButtonGroup>
        </Container>
        <Container>
          <Title>Show Analyses</Title>
          <ToggleButtonGroup
            type="radio"
            className="TeamConfig-toggleButtonGroup"
            name="showAnalyses"
            defaultValue={showAnalyses}
            onChange={setShowAnalyses}
          >
            <ToggleButton
              value={true}
              variant="light"
              id="teamconfig-showanalyses_yes"
              className="TeamConfig-radioButton"
            >
              Yes
            </ToggleButton>
            <ToggleButton
              value={false}
              variant="light"
              id="teamconfig-showanalyses_no"
              className="TeamConfig-radioButton"
            >
              No
            </ToggleButton>
          </ToggleButtonGroup>
        </Container>
        {isCustomerAdmin && (
        <Container>
          <Title>Show Librarian</Title>
          <ToggleButtonGroup
            type="radio"
            className="TeamConfig-toggleButtonGroup"
            name="showLibrarian"
            defaultValue={showLibrarian}
            onChange={setShowLibrarian}
          >
            <ToggleButton
              value={true}
              variant="light"
              id="teamconfig-showlibrarian_yes"
              className="TeamConfig-radioButton"
            >
              Yes
            </ToggleButton>
            <ToggleButton
              value={false}
              variant="light"
              id="teamconfig-showlibrarian_no"
              className="TeamConfig-radioButton"
            >
              No
            </ToggleButton>
          </ToggleButtonGroup>
        </Container>
        )}
        {showForecast && (
          <Container>
            <Title>Show Forecast</Title>
            <Button disabled={true} value={true} variant="light" className="TeamConfig-toggleButtonGroup">
              Yes
            </Button>
          </Container>
        )}
      </div>
      <div className="container-compartment">
        <p>Repositories</p>
        <Container>
          <Select
            name="Repositories"
            collection={assignableRepositories}
            value={repositoryIds}
            onChange={setRepositoryIds}
            menuPosition="fixed"
          />
        </Container>
      </div>
      <div className="container-compartment">
        <p>Chatbots</p>
        <Container>
          <Select
            name="Chatbots"
            collection={chatbots}
            value={chatbotIds}
            onChange={setChatbotIds}
            menuPosition="fixed"
          />
        </Container>
      </div>
      {/* {team && isCustomerAdmin &&
        (team.hasApiToken ? (
          <div className="container-compartment">
            <div className="TokenContainer">
              <Button
                className="btn-deleteToken"
                onClick={() => {
                  dispatch(token.deleteToken(team.id, team.locationId))
                }}
              >
                Delete API token
              </Button>
            </div>
          </div>
        ) : (
          <div className="container-compartment">
            <div className="TokenContainer">
              <Button
                variant="success"
                onClick={() => {
                  dispatch(token.createToken(team.id, team.locationId))
                  setShowToken(true)
                }}
              >
                Create API token
              </Button>
            </div>
          </div>
        ))} */}
    </ConfigContainer>
  )
}
