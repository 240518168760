import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { FiCalendar, FiArchive, FiSettings, FiPlus } from "react-icons/fi"
import { TbSquareLetterP, TbSquareLetterM } from "react-icons/tb"

import "./index.scss"

import { Popup, ListItemDnD, ConfirmDialog } from 'components'
import { MemberConfig } from 'components/Config'
import { members as membersState, teamInfo, projects } from "state_management"
import dragTypes from "utils/scripts/dragTypes"

export default function MembersConfig() {
  const [addConfig, setAddConfig] = useState(false)
  const [activeEdit, setActiveEdit] = useState(null)
  const [activeArchive, setActiveArchive] = useState(null)

  const members = Object.values(useSelector((state) => state.members))
  const dispatch = useDispatch()
  const hasFetched = useSelector((state) => state.teamInfo.hasFetched)

  useEffect(() => {
    if (!hasFetched) {
      dispatch(teamInfo.fetch())
    }
  }, [hasFetched, dispatch])

  const handleArchive = (member) => {
    setActiveArchive(member)
  }

  const confirmArchive = () => {
    if (activeArchive) {
      dispatch(membersState.archive(activeArchive.id)).then(() => {
        dispatch(projects.fetch())
        setActiveArchive(null)
      })
    }
  }

  const handleEdit = (member) => {
    setActiveEdit(member)
  }

  const handleAdd = () => {
    setAddConfig(true)
  }

  const handleDrop = (dragId, dropId) => {
    const dropIdx = members.findIndex((x) => x.id === dropId)
    const dragIdx = members.findIndex((x) => x.id === dragId)
    if (dragIdx > dropIdx) {
      members.splice(dropIdx, 0, members[dragIdx])
      members.splice(dragIdx + 1, 1)
    } else {
      members.splice(dropIdx + 1, 0, members[dragIdx])
      members.splice(dragIdx, 1)
    }
    const ids = members.map((x) => x.id)
    dispatch(membersState.editOrder({ ids: ids }))
  }

  return (
    <div className="MembersConfig-main-container">
      {activeArchive && (
        <ConfirmDialog
          onCancel={() => setActiveArchive(null)}
          onConfirm={confirmArchive}
        >
          <h4>Confirm archival of member: {activeArchive.initials}</h4>
        </ConfirmDialog>
      )}
      {activeEdit && (
        <Popup nonScrollable onCancel={() => setActiveEdit(null)}>
          <MemberConfig member={activeEdit} onClose={() => setActiveEdit(null)} />
        </Popup>
      )}
      {addConfig && (
        <Popup nonScrollable onCancel={() => setAddConfig(false)}>
          <MemberConfig onClose={() => setAddConfig(false)} />
        </Popup>
      )}
      <div className="MembersConfig-member-container">
        {members
          .filter((member) => !member.archived)
          .map((member) => (
            <ListItemDnD
              text={member.initials}
              key={member.id}
              dragType={dragTypes.MEMBER}
              id={member.id}
              item={member}
              onDrop={handleDrop}
            >
              {member.outlookLink && (
                <FiCalendar
                  className="MembersConfig-member-outlook-icon"
                  title="Member Has Enabled iCalendar / Outlook Integration"
                />
              )}
              {member.role === "MEMBER" ? (
                <TbSquareLetterM title="Member" />
              ) : (
                <TbSquareLetterP title="Planner" />
              )}
              <FiArchive
                onClick={() => handleArchive(member)}
                title="Archive Member"
              />
              <FiSettings
                onClick={() => handleEdit(member)}
                title="Edit Member"
              />
            </ListItemDnD>
          ))}
        <div className="MembersConfig-add-member">
          <FiPlus size={40} title="Add Member" onClick={handleAdd} />
        </div>
      </div>
    </div>
  )
}
