import React from "react";
import { FaTimes } from "react-icons/fa";
import "./ReferencesPanel.scss";

const ReferencesPanel = ({ isOpen, onClose, links }) => {
  return (
    <div className={`references-panel ${isOpen ? "open" : ""}`}>
      <div className="references-panel-header">
        <h2>References</h2>
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>
      </div>
      <div className="references-panel-content">
        {links?.length > 0 ? (
          links.map((link, index) => (
            <React.Fragment key={index}>
              <div className="reference-item">
                <a
                  target={link.url.includes(".pdf") ? "_self" : "_blank"}
                  href={link.url}
                  rel="noopener noreferrer"
                  className="reference-link"
                >
                  {link.docId ? `[${link.docId}] - ${link.tag}` : link.tag}
                </a>
              </div>
              {/* Add divider except after the last link */}
              {index < links.length - 1 && <hr className="divider" />}
            </React.Fragment>
          ))
        ) : (
          <p>No references available.</p>
        )}
      </div>
    </div>
  );
};

export default ReferencesPanel;

