import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { ConfigContainer } from 'components'
import { Title, Container, Text, Select } from 'components/Form'
import { librarian, message } from 'state_management'
import { AiOutlineExclamation } from 'react-icons/ai'


export default function ChatbotConfig({ customerAdminView = false, repositories, chatbotData, onCancel }) {
  const dispatch = useDispatch()

  const [name, setName] = useState(chatbotData ? chatbotData.name : "")
  const [systemPrompt, setSystemPrompt] = useState(chatbotData ? chatbotData.systemPrompt : "")
  const [selectedRepositories, setSelectedRepositories] = useState(chatbotData ? chatbotData.repositoryIds : [])
  const role = useSelector((state) => state.auth.role)

  const handleSelectRepositories = (selected) => {
    const repos = repositories.filter((repo) => selected.includes(repo.id))
    if (repos.some((repo) => repo.unprocessedFiles)) {
      dispatch(message.warning("One of the selected repositories has unprocessed files."))
    }
    setSelectedRepositories(selected)
  }

  const onSubmit = () => {
    const input = { name, systemPrompt, repositoryIds: selectedRepositories }
    if (systemPrompt.length > 1200) {
      return dispatch(message.error("Prompt too long (max is 1200 characters)"))
    }
    if (chatbotData) {
      if (role !== "planner") {
        return dispatch(librarian.editAdminChatbot(chatbotData.id, input)).then(onCancel)
      } else {
        dispatch(librarian.editChatbot(chatbotData.id, input)).then(onCancel)
      }
    } else {
      if (role !== "planner") {
        if (customerAdminView) {
          return dispatch(librarian.createGlobalChatbot(input)).then(onCancel)
        } else {
          return dispatch(librarian.createAdminChatbot(input)).then(onCancel)
        }
      } else {
        dispatch(librarian.createChatbot(input)).then(onCancel)
      }
    }
  }
  return (
    <ConfigContainer
      title={chatbotData ? "Edit chatbot" : "Create chatbot"}
      submitHandler={onSubmit}
      cancelHandler={onCancel}
      keyWordForAllowedDeletion="DELETE"
      deleteHandler={chatbotData ? (allowDeletion) => {
        if (allowDeletion === "DELETE") {
          if (chatbotData) {
            dispatch(librarian.deleteChatbot(chatbotData.id)).then(onCancel)
          }
        } else {
          dispatch(message.info("You did not spell DELETE properly"))
        }
      } : undefined}
    >
      <Container>
        <Title>Chatbot Name</Title>
        <Text value={name} required onChange={setName} />
      </Container>
      <Container>
        <Title>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <span>System prompt</span>
            {systemPrompt.length > 1200 && (
              <AiOutlineExclamation
                style={{ color: "red", marginTop: "4px" }}
                size={40}
                title="Prompt too long (max is 1200 characters)"
              />
            )}
          </div>
        </Title>
        <Text large={true} value={systemPrompt} required onChange={setSystemPrompt} />
      </Container>
      <Container>
        <Title>Repositories</Title>
        <Select
          name="Repositories"
          collection={repositories.filter((repo) => repo.isEmpty === false)}
          value={selectedRepositories}
          onChange={handleSelectRepositories}
          menuPosition="fixed"
        />
      </Container>
    </ConfigContainer>
  )
}
