import { request } from "utils/request"
import { asyncAction } from "./common"

const initialState = {
    conversationList: [],
    conversationId: "",
    selectedChatbot: "",
    latestAnswer: "",
    isFetching: false,
    status: "initial",
}

export function reducer(state = initialState, action) {
    switch (action.type) {
    case "chatBot/getAnswer/BEGIN":
      return { ...state, isFetching: true, status: "begun" }
    case "chatBot/getAnswer/ERROR":
      return { ...state, isFetching: false, status: "failed" }
    case "chatBot/getAnswer":
      return {
        ...state,
        conversationList: [...state.conversationList, action.res],
        isFetching: false,
        status: "finished",
      }
    case "chatBot/setChatbot":
      return { ...state, selectedChatbot: action.chatbot }
    case "chatBot/setConversation":
      return { ...state, conversationId: action.conversationId }
    case "chatBot/refreshChat":
    case "auth/login":
    case "auth/verifyMfaToken":
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const getAnswer = asyncAction("chatBot/getAnswer", (dispatch, message, chatbotId, conversationId) => {
  return request("POST", `/librarian/chat/chatbot/${chatbotId}`, { input: { message: message, conversationId: conversationId } }).then((res) => {
    dispatch(setConversation(res.conversationId))
    return res.answer
  })
})

export function setConversation(conversationId) {
    return { type: "chatBot/setConversation", conversationId }
}

export function setChatbot(chatbot) {
    return { type: "chatBot/setChatbot", chatbot }
}

export function refreshChat() {
    return { type: "chatBot/refreshChat" }
}
